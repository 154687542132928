export default ({ store, app }, inject) => {
  const limitText = (text, amount = 15) => {
    if (!text) return null;

    return text.length <= amount ? text : text.substring(0, amount - 3) + "...";
  };

  const limitTexttoHTML = (text, amount = 15, link, moreText) => {
    if (!text) return null;
    let targetLink = "";
    const regex = /https?:\/\/[a-z0-9.-]+(\/\S*?(?=[\.,]*(?:\s|$)))?/g;
    const match = text.match(regex);
    let rawText = text.length <= amount ? text : text.substring(0, amount);

    if (match) {
      targetLink = match[0];
      const rawLink = rawText.match(regex);
      rawText = rawText.replace(
        regex,
        `<a class="text-sm underline hover:text-mainblue" href=${targetLink} target="_blank">${rawLink}</a>`
      );
    }
    return text.length <= amount
      ? rawText
      : rawText +
          `... <a href="${link}" target="_top" class="text-mainblue !hover:text-thirdgray !no-underline">${
            moreText ?? "See More"
          }</a>`;
  };

  const objectArrayParser = (array, targetKey) => {
    let resultList = [];
    array.forEach((item) => {
      if (item[targetKey]) resultList.push(item[targetKey]);
    });
    return resultList;
    // return array.map((el) => {
    //   if (el[targetKey]) return el[targetKey];
    // });
  };
  const formatCurrency = (price) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(price);
  };
  const queryGenerator = (object, isFirst = true) => {
    let params = "";
    const paramsArray = Object.keys(object).filter((key) => object[key]);
    paramsArray.forEach((key, i) => {
      if (object[key]) {
        if (i === 0 && isFirst) params += `?${key}=${object[key]}`;
        else params += `&${key}=${object[key]}`;
      }
    });
    return params;
  };
  inject("limitText", limitText);
  inject("limitTexttoHTML", limitTexttoHTML);
  inject("objectArrayParser", objectArrayParser);
  inject("formatCurrency", formatCurrency);
  inject("queryGenerator", queryGenerator);
};
