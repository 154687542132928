export const state = () => ({
  profile: {
    community_name: "",
  },
});

export const mutations = {
  SET_COMMUNITY_PROFILE: (state, data) => {
    state.profile = data;
  },
};

export const actions = {
  getAllCommunities({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(process.env.BASE_API + "view/communities" + params)
        .then(function (response) {
          const res = response.data;
          resolve(res.body);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  getCommunityListing({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(process.env.BASE_API + "marketplace/listing" + params)
        .then(function (response) {
          const res = response.data;
          resolve(res.body);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  postSubmitJoinCommunity({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      this.$axios
        .post(process.env.BASE_API + "communities/member", data)
        .then(function (response) {
          const res = response.data;
          resolve(res);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  postSubmitLeaveCommunity({ commit }, payload) {
    const { id, formData } = payload;
    return new Promise((resolve, reject) => {
      this.$axios
        .post(process.env.BASE_API + `communities/member/${id}`, formData)
        .then(function (response) {
          const res = response.data;
          resolve(res);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  setCommunityMember({ commit }, payload) {
    const { id, formData } = payload;
    return new Promise((resolve, reject) => {
      this.$axios
        .post(process.env.BASE_API + `communities/member/${id}`, formData)
        .then(function (response) {
          const res = response.data;
          resolve(res);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  deleteCommunity({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .delete(process.env.BASE_API + `communities/${id}`)
        .then(function (response) {
          const res = response.data;
          resolve(res);
        })
        .catch(function (error) {
          reject(error.response);
        });
    });
  },
  toggleCommunityNotification({ commit, rootState }, data) {
    const { id, receive_notification } = data;
    const dataForm = new FormData();
    dataForm.append(
      "receive_notification",
      +rootState.auth.user.member.receive_notification === 0 ? 1 : 0
    );
    return new Promise((resolve, reject) => {
      this.$axios
        .post(process.env.BASE_API + "communities/member/" + id, dataForm)
        .then((response) => {
          const { data } = response;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
