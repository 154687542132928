export const state = () => ({
})

export const mutations = {
}

export const actions = {
  fetchMember ({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios.get(process.env.BASE_API + `communities/${id}/member/check`)
        .then(function (response) {
          const res = response.data
          resolve(res)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  fetchTags ({ commit }, keyword) {
    return new Promise((resolve, reject) => {
      this.$axios.get(process.env.BASE_API + 'tags?tag_name=' + keyword)
        .then(function (response) {
          const res = response.data
          resolve(res)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  getQuestionsAnswers ({ commit }, params) {
    return new Promise((resolve, reject) => {
      this.$axios.get(process.env.BASE_API + 'view/communities/questions/' + params.qid + '/answers?limit=' + params.limit + '&p=' + params.offset)
        .then(function (response) {
          const res = response.data
          resolve(res)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  submitAnswer ({ commit }, formData) {
    return new Promise((resolve, reject) => {
      this.$axios.post(process.env.BASE_API + 'communities/answers', formData)
        .then(function (response) {
          const res = response.data
          resolve(res.body)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  pinnQuestion ({ commit }, payload) {
    const { id, formData } = payload
    return new Promise((resolve, reject) => {
      this.$axios.post(process.env.BASE_API + `communities/questions/${id}`, formData)
        .then(function (response) {
          const res = response.data
          resolve(res.body)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  deleteQuestion ({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(process.env.BASE_API + `communities/questions/${id}`)
        .then(function (response) {
          const res = response.data
          resolve(res.body)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  deleteAnswer ({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(process.env.BASE_API + `communities/answers/${id}`)
        .then(function (response) {
          const res = response.data
          resolve(res.body)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  },
  editAnswer ({ commit }, payload) {
    const { id, formData } = payload
    return new Promise((resolve, reject) => {
      this.$axios.post(process.env.BASE_API + `communities/answers/${id}`, formData)
        .then(function (response) {
          const res = response.data
          resolve(res.body)
        })
        .catch(function (error) {
          reject(error.response)
        })
    })
  }
}
