export default ({ store, app }, inject) => {
  const getQuestion = async (idCommunity, questionID) => {
    try {
      if (!idCommunity || !questionID) {
        throw new Error("Required ID Community and question ID");
      }
      const response = await app.$axios.get(
        process.env.BASE_API +
          `view/communities/${idCommunity}/questions/${questionID}`
      );

      return await response.data.body;
    } catch (err) {
      throw err;
    }
  };

  const editQuestion = async (questionID, data) => {
    try {
      const response = await app.$axios.post(
        process.env.BASE_API + `communities/questions/${questionID}`,
        data
      );
      return await response.data.body;
    } catch (err) {
      throw err;
    }
  };
  inject("getQuestion", getQuestion);
  inject("editQuestion", editQuestion);
};
