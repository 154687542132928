export default ({ store, app }, inject) => {
  const setMemberStatus = async (id, status) => {
    if (!id || !status) {
      throw new Error(
        "Required paramter not full Filled" + ` : ${!id ? "id" : "status"}`
      );
    }
    try {
      const res = await app.$axios.post(
        process.env.BASE_API + `communities/member/${id}`,
        { status }
      );

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  const setMemberPosition = async (id, position) => {
    if (!id || !position) {
      throw new Error(
        "Required paramter not full Filled" + ` : ${!id ? "id" : "position"}`
      );
    }
    try {
      const res = await app.$axios.post(
        process.env.BASE_API + `communities/member/${id}`,
        { position }
      );

      return res.data;
    } catch (err) {
      throw err;
    }
  };

  inject("setMemberStatus", setMemberStatus);
  inject("setMemberPosition", setMemberPosition);
};
