import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import linkify from 'vue-linkify'
import VueCookies from 'vue-cookies'
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'
// import VueTour from 'vue-tour'
// Vue.prototype.$ELEMENTUI = { size: 'small', zIndex: 3000 }

Vue.directive('linkified', linkify)
Vue.use(ElementUI, { locale, size: 'small', zIndex: 3000 })
Vue.use(VueCookies)
// Vue.use(VueTour)
// Vue.use(Vuesax)
